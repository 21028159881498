




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// Dummy comment for deployment
import { ref, watch, computed, onMounted } from '@vue/composition-api';
import store, { useUserState, useUserActions } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import Profile from '@/components/Profile.vue';
import Testimonial from '@/components/landing/Testimonial.vue';

import Hero from '@/components/landing/Hero.vue';
import { PATHWAY_OPTIONS, USER_ROLES, TIME_LINES, PROGRAMS } from '@/constants/landing';
import { useVideoAsk } from '@/composables/useVideoAsk';
import { OperationMode } from '@/constants/operationMode';
import StoriesGrid from '@/views/Stories.vue';

export default {
  name: 'Landing',
  components: {
    Profile,
    Testimonial,
    Hero,
    HeroPrograms: () => import('@/components/molecules/m-hero-programs.vue'),
    MSponsorLinkInfo: () => import('@/components/molecules/m-sponsor-link-info.vue'),
    SetupWizard: () => import('./Guide/components/SetupWizard/SetupWizard.vue'),
    MSelectTemplate: () => import('@/components/molecules/settings/m-select-template.vue'),
    StoriesGrid,
    MBasicUserInfo: () => import('@/components/molecules/m-basic-user-info-dialog.vue')
  },
  props: {
    shareCode: {
      type: String,
      required: false,
      default: ''
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    userId: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, ctx) {
    const {
      getSponsorLinkByShareCode,
      fetchUserById,
      getOrganizationByLink,
      getUserByKeyAndValue
    } = useUserActions([
      'getSponsorLinkByShareCode',
      'fetchUserById',
      'getOrganizationByLink',
      'getUserByKeyAndValue'
    ]);

    const starterEmail = ref('');
    const landingTitle = ref('Explore Programs');
    const landingFirstName = ref('');
    const landingLastName = ref('');
    const landingOrgName = ref('');
    const localEdit = ref(false);
    const currentRole = ref('Student');
    const roleColor = ref('green');
    const roleLabel = ref('What employer projects would you like to explore?');
    const roles = ref(USER_ROLES);
    const { user } = useUserState(['user']);
    const currentOrigin = window.location.origin;
    const isSetupWizardOpen = ref(false);
    const selectTemplate = ref(false);
    const isFromAutoSetupValue = ref(false);
    const selectedScope = ref({});
    const isLoginPopupVisible = ref(false);

    const changeRoleTo = (role: string) => {
      roles.value.student.show = true;
      roles.value.teacher.show = true;
      roles.value.school.show = true;
      roles.value.parent.show = true;
      roles.value.employer.show = true;
      roles.value.sponsor.show = true;

      switch (role) {
        case 'Student':
          roleLabel.value = 'What employer projects would you like to explore?';
          roleColor.value = roles.value.student.color;
          roles.value.student.show = false;
          break;
        case 'Teacher':
          roleLabel.value = 'What pathways do you teach in your class?';
          roleColor.value = roles.value.teacher.color;
          roles.value.teacher.show = false;
          break;
        case 'School':
          roleLabel.value = 'What employer projects would you like to explore?';
          roleColor.value = roles.value.school.color;
          roles.value.school.show = false;
          break;
        case 'Parent':
          roleLabel.value = 'What careers do you want your student to explore?';
          roleColor.value = roles.value.parent.color;
          roles.value.parent.show = false;
          break;
        case 'Employer':
          roleLabel.value = 'Check out employers in your industry';
          roleColor.value = roles.value.employer.color;
          roles.value.employer.show = false;
          break;
        case 'Sponsor':
          roleLabel.value = 'What workforce initiatives do you want to fund?';
          roleColor.value = roles.value.sponsor.color;
          roles.value.sponsor.show = false;
          break;
        default:
          break;
      }
      currentRole.value = role;
    };
    const pathwayPresets = ref(['All']);
    const handlePathwaysInput = () => {
      if (
        pathwayPresets.value.length > 1 &&
        pathwayPresets.value[pathwayPresets.value.length - 1] === 'All'
      )
        pathwayPresets.value = pathwayPresets.value.filter(filter => filter === 'All');
      else if (pathwayPresets.value.length > 1)
        pathwayPresets.value = pathwayPresets.value.filter(filter => filter !== 'All');
    };

    const updateList = newData => {
      if (newData.includeChangeTitleText) {
        landingTitle.value = newData.landingTitleText;
        landingFirstName.value = newData.landingSponsorFirstName;
        landingLastName.value = newData.landingSponsorLastName;
        landingOrgName.value = newData.landingOrganizationName;
      } else {
        landingTitle.value = 'Explore Programs';
        landingFirstName.value = newData.landingSponsorFirstName;
        landingLastName.value = newData.landingSponsorLastName;
        landingOrgName.value = newData.landingOrganizationName;
      }
    };

    function openAuthPopup() {
      isLoginPopupVisible.value = true;
    }

    onMounted(() => {
      // Access the route params via ctx.root.$route
      const { openLoginPopup } = ctx.root.$route.params;

      if (openLoginPopup === 'true') {
        openAuthPopup();
      }
    });

    // watch(newpass => {
    //   window.videoask.loadEmbed({
    //     kind: 'widget',
    //     url: 'https://www.videoask.com/f9u4t8kem',
    //     options: {
    //       widgetType: 'VideoThumbnailWindowTall',
    //       text: 'Watch PilotCity TV',
    //       backgroundColor: '#000000',
    //       position: 'bottom-right'
    //     }
    //   });
    // });

    const operationMode = computed(() => {
      return OperationMode.Landing;
    });

    function openCreateProgram(val) {
      selectTemplate.value = true;
      isFromAutoSetupValue.value = true;
      selectedScope.value = val;
    }

    const userType = computed(() => {
      let userType: 'organizer' | 'stakeholder' | 'participant' | '' = 'stakeholder';

      if (ctx.root.$route.query && ctx.root.$route.query.program) {
        userType = '';
      }
      return userType;
    });
    useVideoAsk(user, operationMode, userType);

    const demoDialog = ref(false);
    const studentsDialog = ref(false);
    const teachersDialog = ref(false);
    const schoolsDialog = ref(false);
    const parentsDialog = ref(false);
    const employersDialog = ref(false);
    const sponsorsDialog = ref(false);
    const sponsorDialog = ref(false);

    const isOpenPopup = ref(false);
    const sponsorData = ref(null);
    const getSponsorInfo = async shareCode => {
      const sponsorLink = await getSponsorLinkByShareCode({ share_code: shareCode });
      const keysValue = Object.keys(sponsorLink?.claimedBy);
      const sponsorUserId = '';
      const claimTimes = keysValue?.filter(
        key => key?.toString() === user?.value?._id?.toString()
      )?.length;
      const ENDPOINT =
        process.env.VUE_APP_NODE_ENV === 'production'
          ? 'https://pilotcity.com'
          : 'https://stage.pilotcity.com';

      localStorage.setItem('programSettings', JSON.stringify(sponsorLink.programSettings));
      if (sponsorLink && claimTimes < sponsorLink?.claimQuotaPerUser) {
        const sponsorInfo: any = {};
        let organizationDetails: any;
        if (sponsorLink?.type === 'personal') {
          const userInfo = await getUserByKeyAndValue({
            key: '_id',
            value: decodeURIComponent(sponsorLink?.createdBy)
          });
          // const userInfo = await fetchUserById({ user_id: sponsorLink?.createdBy });
          sponsorInfo.userDetails = userInfo?.data;
          sponsorInfo.type = 'user';
          sponsorInfo.sponsorUserId = decodeURIComponent(sponsorLink?.createdBy);
          if (
            user?.value?._id.toString() === decodeURIComponent(sponsorLink?.createdBy) &&
            props?.isEdit
          ) {
            localEdit.value = true;
          } else {
            localEdit.value = false;
          }
        } else if (sponsorLink?.type === 'organization') {
          const userInfoOrg = await getUserByKeyAndValue({
            key: '_id',
            value: sponsorLink?.userId
          });
          sponsorInfo.userDetails = userInfoOrg?.data;
          const link = `${ENDPOINT}/join/${sponsorLink?.createdBy}`;
          organizationDetails = await getOrganizationByLink(link);
          sponsorInfo.type = 'organization';
          sponsorInfo.sponsorUserId = sponsorLink?.userId;
          sponsorInfo.name = organizationDetails?.name;
          if (user?.value?._id.toString() === sponsorLink?.userId.toString() && props?.isEdit) {
            localEdit.value = true;
          } else {
            localEdit.value = false;
          }
        }
        sponsorInfo.programSettings = sponsorLink?.programSettings;
        localStorage.setItem('sponsorAllData', JSON.stringify(sponsorInfo));
        sponsorInfo.sponsorLink = `${ENDPOINT}/sponsor/${sponsorLink?.shareCode}`;
        sponsorInfo.shareCode = sponsorLink?.shareCode;
        // if (sponsorInfo) {
        //   if (sponsorLink?.creationType === 'auto') {
        //     const wallet = await getWalletByOwnerId({ owner_id: sponsorLink?.createdBy });
        //     if (wallet) {
        //       sponsorInfo.balance = wallet?.balance;
        //     }
        //   } else {
        //     sponsorInfo.balance = sponsorLink?.index || 0;
        //   }
        // }
        if (
          sponsorLink?.programSettings?.landingTitleText &&
          sponsorLink?.programSettings?.includeChangeTitleText
        ) {
          landingTitle.value = sponsorLink?.programSettings?.landingTitleText;
          landingFirstName.value = sponsorLink?.programSettings?.landingSponsorFirstName;
          landingLastName.value = sponsorLink?.programSettings?.landingSponsorLastName;
          landingOrgName.value = sponsorLink?.programSettings?.landingOrganizationName;
        } else {
          landingTitle.value = 'Explore Programs';
          landingFirstName.value = sponsorInfo?.userDetails?.firstName;
          landingLastName.value = sponsorInfo?.userDetails?.lastName;
          landingOrgName.value = organizationDetails?.name ? organizationDetails?.name : '';
        }
        sponsorData.value = sponsorInfo;
        isOpenPopup.value = true;
      }
      if (localStorage.getItem('sponsorShipLinkPath')) {
        localStorage.removeItem('sponsorShipLinkPath');
      }
    };
    if (props.shareCode) {
      getSponsorInfo(props.shareCode);
    }
    if (
      localStorage?.getItem(`sponsorInfo`) &&
      props?.shareCode !== JSON.parse(localStorage?.getItem(`sponsorInfo`))?.shareCode
    ) {
      localStorage?.removeItem(`sponsorInfo`);
      localStorage?.removeItem(`sponsorAllData`);
      store.dispatch(`tools/${ActionTypes.disableBalloonPopup}`);
      getSponsorInfo(props.shareCode);
    }
    const phoneDialog = ref(false);
    const people = [
      {
        name: 'Jerold Inocencio',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JeroldInocencio.png'
      },
      {
        name: 'Parul Veda',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ParulVeda.png'
      },
      {
        name: 'Noah MacLean',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NoahMacLean.png'
      },
      {
        name: 'Riddhi Bhandari',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiddhiBhandari.png'
      },
      {
        name: 'Timothy Deloney',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TimDeloney.png'
      },
      {
        name: 'Prajit Saravanan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrajitSaravanan.png'
      },
      {
        name: 'Jason Yang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JasonYang.png'
      },
      {
        name: 'Nick Falk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NickFalk.png'
      },
      {
        name: 'Samantha Wilcox',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SamanthaWilcox.png'
      },
      {
        name: 'Ayan Shah',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyanShah.png'
      },
      {
        name: 'Devarsh Jayakrishnan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DevarshJayakrishnan.png'
      },
      {
        name: 'Erik Pedersen',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErikPedersen.png'
      }
    ];

    return {
      errors: [],
      pathwaySearch: null,
      pathwayOptions: PATHWAY_OPTIONS,
      pathwayPresets,
      handlePathwaysInput,
      user,
      starterEmail,
      currentRole,
      isLoginPopupVisible,
      changeRoleTo,
      roleLabel,
      roleColor,
      roles,
      snackbar: true,
      timeLines: TIME_LINES,
      programs: PROGRAMS,
      currentOrigin,
      demoDialog,
      studentsDialog,
      teachersDialog,
      schoolsDialog,
      employersDialog,
      parentsDialog,
      sponsorsDialog,
      sponsorDialog,
      isOpenPopup,
      sponsorData,
      isSetupWizardOpen,
      selectTemplate,
      openCreateProgram,
      selectedScope,
      isFromAutoSetupValue,
      phoneDialog,
      people,
      localEdit,
      landingTitle,
      landingFirstName,
      openAuthPopup,
      landingLastName,
      landingOrgName,
      updateList
    };
  },
  computed: {
    isShareCodeLink() {
      return !!this.shareCode;
    }
  }
};
